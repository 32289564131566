import { Paper, Typography } from "../../../UIComponents";
import Table from "../../../UIComponents/DesignSystem/Table";
import TableBody from "../../../UIComponents/DesignSystem/TableBody";
import TableCell from "../../../UIComponents/DesignSystem/TableCell";
import TableContainer from "../../../UIComponents/DesignSystem/TableContainer";
import TableHead from "../../../UIComponents/DesignSystem/TableHead";
import TableRow from "../../../UIComponents/DesignSystem/TableRow";

const PMGrageInfo = () => {
  return (
    <>
      <Typography variant="h6" fontWeight={900} gutterBottom>
        PM Grade Calculation
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="PM Grade Calculation">
          <TableHead>
            <TableRow>
              <TableCell>Criteria</TableCell>
              <TableCell>Weight</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Unassigned Stories</TableCell>
              <TableCell>25%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Missing Date</TableCell>
              <TableCell>25%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Missing Est Time</TableCell>
              <TableCell>25%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Incorrect Status</TableCell>
              <TableCell>25%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" fontWeight={900} gutterBottom sx={{ mt: 2 }}>
        Grading Scale
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Grading Scale">
          <TableHead>
            <TableRow>
              <TableCell>Grade</TableCell>
              <TableCell>Percentage Range</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>A+</TableCell>
              <TableCell>90% - 100%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A</TableCell>
              <TableCell>80% - 89%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B</TableCell>
              <TableCell>65% - 79%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>C</TableCell>
              <TableCell>60% - 64%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>F</TableCell>
              <TableCell>0% - 59%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PMGrageInfo;
