import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton.js";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";

import { Collapse } from "@mui/material";
import { healthReportMapping } from "./CollapsibleTable.js";
import HistoricalHealthReportDetails from "./HistoricalHealthReportDetails.js";
import { Stack } from "../../../UIComponents/index.js";
import { getValueFromPath } from "../../../utils.js";

function calculateGrade({
  budget,
  client_engagement,
  client_timeliness,
  schedule,
}) {
  const maxScore = 20; // 5 for each of the 4 variables
  const totalScore =
    Number(budget) +
    Number(client_engagement) +
    Number(client_timeliness) +
    Number(schedule);

  const percentage = (totalScore / maxScore) * 100;

  // Determine grade using switch case
  switch (true) {
    case percentage >= 90 && percentage <= 100:
      return "A+";
    case percentage >= 80 && percentage < 90:
      return "A";
    case percentage >= 65 && percentage < 80:
      return "B";
    case percentage >= 60 && percentage < 65:
      return "C";
    default:
      return "F";
  }
}

const HistoricalHealthReportRow = ({ report, openPHR, handlePHRClick }) => {
  const shouldShowDetails = false;

  const project_grade = calculateGrade({
    budget: report.budget,
    schedule: report.schedule,
    client_engagement: report.client_engagement,
    client_timeliness: report.client_timeliness,
  });

  report.project_grade = project_grade;

  return (
    <>
      <TableRow style={{ padding: 0 }}>
        <TableCell />
        {shouldShowDetails && (
          <TableCell>
            <IconButton
              size="small"
              onClick={() => handlePHRClick(report.id)}
              sx={{ maxWidth: "36px" }}
            >
              {openPHR[report.id] ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        {Object.keys(healthReportMapping).map((key) => (
          <TableCell key={key}>
            <Stack
              justifyContent="center"
              alignItems="center"
            >{`${getValueFromPath(report, key)}`}</Stack>
          </TableCell>
        ))}
      </TableRow>
      {shouldShowDetails && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={18}>
            <Collapse in={openPHR[report.id]} timeout="auto" unmountOnExit>
              <HistoricalHealthReportDetails report={report} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default HistoricalHealthReportRow;
